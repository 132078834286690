body { background-color: $COL1; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL12;
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 26px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL13;
font-family: 'Oswald';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL14;
font-family: 'Oswald';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL15;
font-family: 'Oswald';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h5 {
color: $COL16;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 24px;

}
h6 {
color: $COL17;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 18px;

}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
ol,ul {
color: $COL11;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
summary {
font-family: 'Oswald';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
p { padding-bottom: 1em; }
a {color: $COL8}
a:hover {color: $COL26}
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL9;
 }
/* Primary:4 */
.MES4 {
background-color: $COL2;
color: $COL9;
 }
.MES4 {
background-color: $COL2;
color: $COL9;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL9;
 }
 }
cite.MEC4{
color: $COL9;
}
/* Secondary:5 */
.MES5 {
background-color: $COL3;
color: $COL10;
 }
/* Secondary:6 */
.MES6 {
background-color: $COL3;
color: $COL10;
 }
.MES6 {
background-color: $COL3;
color: $COL10;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL10;
 }
 }
cite.MEC6{
color: $COL10;
}
/* Dark:7 */
.MES7 {
background-color: $COL4;
&:hover { background-color: $COL4;}
color: $COL18;
&:hover { color: $COL20;}
 }
/* Dark:8 */
.MES8 {
background-color: $COL22;
color: $COL1;
 }
.MES8 {
background-color: $COL22;
color: $COL1;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL1;
 }
 }
a.MEC8 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC8{
color: $COL1;
}
/* Light:9 */
.MES9 {
background-color: $COL5;
color: $COL27;
 }
/* Light:10 */
.MES10 {
background-color: $COL20;
color: $COL27;
 }
.MES10 {
background-color: $COL20;
color: $COL27;
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: $COL27;
 }
 }
cite.MEC10{
color: $COL27;
}
/* Shade 1:11 */
.MES11 {
background-color: $COL6;
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL6;
 }
.MES12 {
background-color: $COL6;
 }
/* Menu:13 */
.MES13 {
background-color: $COL21;
color: $COL4;
font-size: 14px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
 }
.MES13 {
background-color: $COL21;
color: $COL4;
font-size: 14px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
 }
a.MEC13 { color: $COL20;
&:hover { color: $COL5; }
 }
cite.MEC13{
color: $COL4;
font-size: 14px;
}
/* home block blue:14 */
.MES14 {
background-color: $COL22;
color: $COL20;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES14 {
background-color: $COL22;
color: $COL20;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL20;
 }
 }
.MEC14 li {color: $COL20;}
cite.MEC14{
color: $COL20;
}
/* Header Panel:15 */
.MES15 {
background-color: $COL20;
color: $COL22;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL2 transparent transparent transparent;
 }
.MES15 {
background-color: $COL20;
color: $COL22;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL2 transparent transparent transparent;
 }
cite.MEC15{
color: $COL22;
}
/* Hollaw:16 */
.MES16 {
color: $COL20;
&:hover { color: $COL20;}
padding: 10px 40px;

border-width: 2px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }
/* Banner Slider:17 */
.MES17 {
& .slider-arrow {color: $COL24;
font-size: 80px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: $COL20;
} }
/* Contents:18 */
.MES18 {
background-color: $COL20;
color: $COL22;
font-size: 14px;
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
.MES18 {
background-color: $COL20;
color: $COL22;
font-size: 14px;
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
cite.MEC18{
color: $COL22;
font-size: 14px;
}
/* Testim Block:19 */
.MES19 {
background-color: $COL5;
color: $COL22;
font-size: 14px;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
.MES19 {
background-color: $COL5;
color: $COL22;
font-size: 14px;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
cite.MEC19{
color: $COL22;
font-size: 14px;
}
/* Footer Dark:20 */
.MES20 {
background-color: $COL22;
color: $COL1;
font-size: 11.2px;
 }
.MES20 {
background-color: $COL22;
color: $COL1;
font-size: 11.2px;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL1;
 }
 }
a.MEC20 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC20{
color: $COL1;
font-size: 11.2px;
}
/* News grid:21 */
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
 }
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: $COL22;
 }
 }
cite.MEC21{
color: $COL4;
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: $COL5;
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: $COL2;
}
color: $COL20;
& > article { color: $COL20;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Secondary:24 */
.MES24 {
color: $COL22;
border-width: 2px;
border-style: solid;
border-color: $COL20;
 }
/* Breacrumb:25 */
.MES25 {
background-color: $COL20;
color: $COL22;
font-size: 14px;
padding: 10px 15px;

 }
.MES25 {
background-color: $COL20;
color: $COL22;
font-size: 14px;
padding: 10px 15px;

 }
a.MEC25 { color: $COL2;
&:hover { color: $COL4; }
 }
cite.MEC25{
color: $COL22;
font-size: 14px;
}
/* menu style:26 */
nav.me-Menu.MES26 {
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: $COL22;
text-transform: uppercase;
}
 &:hover > a.MEC26{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC26 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC26 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC26 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL20;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC26 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 30px 15px;}

& .sub-menu .menu-item a{padding: 10px;}


& > .menu-item.MEC26.active { & > a{ color: $COL2;}
 }
&.horizontal .menu-item.MEC26:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL3;}}
&.vertical .menu-item.MEC26:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL3;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC26{background-color: $COL3;
 &:hover {background-color: $COL2}
}
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: $COL20;
}
 &:hover > a.MEC26{color: $COL20;
}
 }

}
}
 }
/* Top banner panel:27 */
.MES27 {
background-color: $COL22;
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ridgeplumbing.com.au/img/587/77');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
font-size: 14px;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
padding: 0 15px;

 }
.MES27 {
background-color: $COL22;
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ridgeplumbing.com.au/img/587/77');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
font-size: 14px;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
padding: 0 15px;

h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: $COL20;
 }
 }
cite.MEC27{
color: $COL20;
font-size: 14px;
}
/* menu style transparent:28 */
nav.me-Menu.MES28 {
.menu-item.MEC28{background-color: $COL23;
}
& .menu-item.MEC28, & .menu-item.MEC28 > div.MEC28{ & > a.MEC28{color: $COL22;
font-size: 14px;
text-transform: uppercase;
}
 &:hover > a.MEC28{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC28 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC28 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC28 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC28 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* grid panel:30 */
.MES30 {
background-color: $COL5;
color: $COL27;
border-width: 1px;
border-style: solid;
border-color: $COL1;
 }
.MES30 {
background-color: $COL5;
color: $COL27;
border-width: 1px;
border-style: solid;
border-color: $COL1;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: $COL27;
 }
 }
cite.MEC30{
color: $COL27;
}
/* Panel Contents:31 */
.MES31 {
background-color: $COL20;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL28;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL22;
font-size: 14px;
padding: 15px;

 }
.MES31 {
background-color: $COL20;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL28;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL22;
font-size: 14px;
padding: 15px;

 }
cite.MEC31{
color: $COL22;
font-size: 14px;
}
/* Blue line:32 */
.MES32 {
& > hr {border-width: 0 0 1px 0;}
& > hr {border-color: transparent transparent $COL2 transparent;}
 }
/* Shade 2:33 */
.MES33 {
background-color: $COL29;
 }
/* Shade 2:34 */
.MES34 {
background-color: $COL29;
color: $COL20;
 }
.MES34 {
background-color: $COL29;
color: $COL20;
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL5;
 }
 }
cite.MEC34{
color: $COL20;
}
/* Shade 3:35 */
.MES35 {
background-color: $COL30;
 }
/* Shade 3:36 */
.MES36 {
background-color: $COL30;
 }
.MES36 {
background-color: $COL30;
 }
/* image panel:37 */
.MES37 {
background-color: $COL3;
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ridgeplumbing.com.au/img/587/77');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
font-size: 14px;
@media #{$medium-up} {
font-size: 16.8px;
};
@media #{$large-up} {
font-size: 18.2px;
};
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
.MES37 {
background-color: $COL3;
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ridgeplumbing.com.au/img/587/77');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
font-size: 14px;
@media #{$medium-up} {
font-size: 16.8px;
};
@media #{$large-up} {
font-size: 18.2px;
};
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px;

}
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: $COL20;
 }
 }
cite.MEC37{
color: $COL20;
font-size: 14px;
@media #{$medium-up} {
font-size: 16.8px;
};
@media #{$large-up} {
font-size: 18.2px;
};
}
/* Need a Plumber:38 */
.MES38 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.15 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ridgeplumbing.com.au/img/805/140');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
font-size: 14px;
min-height:100px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 100px;};
padding: 0 15px;

 }
.MES38 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.15 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ridgeplumbing.com.au/img/805/140');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
font-size: 14px;
min-height:100px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 100px;};
padding: 0 15px;

h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: $COL20;
 }
 }
cite.MEC38{
color: $COL20;
font-size: 14px;
}
/* Panel Contents Blue:39 */
.MES39 {
background-color: $COL2;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL28;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 15.4px;
};
padding: 15px;

 }
.MES39 {
background-color: $COL2;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL28;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 15.4px;
};
padding: 15px;

h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL20;
 }
 }
a.MEC39 { color: $COL20;
&:hover { color: $COL20; }
 }
cite.MEC39{
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 15.4px;
};
}
/* Emergency Popup:41 */
.MES41 {
background-color: $COL2;
color: $COL20;
font-size: 14px;
padding: 15px;

 }
.MES41 {
background-color: $COL2;
color: $COL20;
font-size: 14px;
padding: 15px;

h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: $COL20;
 }
 }
a.MEC41 { color: $COL20;
&:hover { color: $COL20; }
 }
cite.MEC41{
color: $COL20;
font-size: 14px;
}
/* White line:42 */
.MES42 {
& > hr {border-width: 0 0 1px 0;}
& > hr {border-color: transparent transparent $COL20 transparent;}
 }
/* Panel Contents Orange:43 */
.MES43 {
background-color: $COL29;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL28;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 15.4px;
};
padding: 15px;

 }
.MES43 {
background-color: $COL29;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: $COL28;}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 15.4px;
};
padding: 15px;

h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: $COL20;
 }
 }
a.MEC43 { color: $COL20;
&:hover { color: $COL20; }
 }
cite.MEC43{
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 15.4px;
};
}
/* Enquiry Button:44 */
.MES44 {
background-color: $COL30;
&:hover { background-color: $COL2;}
color: $COL20;
&:hover { color: $COL20;}
padding: 10px 40px;

border-width: 2px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }

$COL1: #e6e7e8; //
$COLFLAT1: #e6e7e8; //
$COL2: #0067ac; //
$COLFLAT2: #0067ac; //
$COL3: #657b99; //
$COLFLAT3: #657b99; //
$COL4: #333333; //
$COLFLAT4: #333333; //
$COL5: #f2f2f2; //
$COLFLAT5: #f2f2f2; //
$COL6: #848484; //
$COLFLAT6: #848484; //
$COL7: #000000; //
$COLFLAT7: #000000; //
$COL8: #0067ac; //
$COLFLAT8: #0067ac; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #000000; //
$COLFLAT11: #000000; //
$COL12: #1b1b1b; //
$COLFLAT12: #1b1b1b; //
$COL13: #1b1b1b; //
$COLFLAT13: #1b1b1b; //
$COL14: #1b1b1b; //
$COLFLAT14: #1b1b1b; //
$COL15: #1b1b1b; //
$COLFLAT15: #1b1b1b; //
$COL16: #1b1b1b; //
$COLFLAT16: #1b1b1b; //
$COL17: #0067ac; //
$COLFLAT17: #0067ac; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: rgba(8,8,8,0.478); //Dark transparent
$COLFLAT21: #474747; //Dark transparent
$COL22: #000000; //Black
$COLFLAT22: #000000; //Black
$COL23: rgba(0,0,0,0); //
$COLFLAT23: #808080; //
$COL24: rgba(255,255,255,0.478); //White transparent
$COLFLAT24: #bdbdbd; //White transparent
$COL25: rgba(190,20,27,0.82); //Red transparent
$COLFLAT25: #b3272d; //Red transparent
$COL26: #000000; //
$COLFLAT26: #000000; //
$COL27: #000000; //
$COLFLAT27: #000000; //
$COL28: rgba(101,122,152,0.549); //Transparent Blue
$COLFLAT28: #717d8d; //Transparent Blue
$COL29: #ffa000; //
$COLFLAT29: #ffa000; //
$COL30: #496887; //
$COLFLAT30: #496887; //
$COL31: rgba(255,255,255,0.102); //White transparent 10%
$COLFLAT31: #8d8d8d; //White transparent 10%
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
